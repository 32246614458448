import React, { useEffect, useState } from 'react';
import { Routes, Route, Navigate, useLocation, useNavigate } from 'react-router-dom';
import Dashboard from './pages/Dashboard/Dashboard';
import Login from './pages/Login/Login';
import MagicLink from './pages/MagicLink/MagicLink';
import NotFound from './pages/NotFound/NotFound';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useDispatch, useSelector } from 'react-redux';
import { authenticateViaMagicLink, authenticateViaMagicLinkFailure } from './redux/slices/authSlice';
import { validateMagicLinkToken } from './services/validateTokenService';
import { fetchContactIdByEmail } from './services/fetchContactIdService';

function App() {
  const { isAuthenticated } = useSelector(state => state.auth);
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const [isValidatingToken, setIsValidatingToken] = useState(true); // Add loading state

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const tokenFromUrl = queryParams.get('token');
    const storedToken = sessionStorage.getItem('magicToken');

    const validateTokenAndFetchContact = async (token) => {
      try {
        const responseData = await validateMagicLinkToken(token);
        dispatch(authenticateViaMagicLink(responseData)); // Authenticate user on success
        sessionStorage.setItem('email', responseData.email); // Store validated email

        // Fetch contact ID (validates that user exists in CRM)
        await fetchContactIdByEmail(responseData.email);
        navigate('/dashboard'); // Redirect to dashboard after successful validation
      } catch (error) {
        console.error('Token validation or contact fetching failed:', error.message);
        dispatch(authenticateViaMagicLinkFailure('Invalid or expired token'));
        sessionStorage.removeItem('magicToken');
        navigate('/login'); // Redirect to login on validation failure
      } finally {
        setIsValidatingToken(false); // Finish validation process
      }
    };

    if (tokenFromUrl && !storedToken) {
      sessionStorage.setItem('magicToken', tokenFromUrl);
      validateTokenAndFetchContact(tokenFromUrl);
    } else if (storedToken && !isAuthenticated) {
      validateTokenAndFetchContact(storedToken);
    } else {
      setIsValidatingToken(false); // If no token, stop loading
    }
  }, [location, dispatch, navigate, isAuthenticated]);

  const requireAuth = (element) => {
    if (isValidatingToken) {
      return <div>Loading...</div>; // Show loading until token is validated
    }
    if (!isAuthenticated) {
      return <Navigate to="/login" />; // Redirect to login if not authenticated
    }
    return element;
  };

  return (
    <div className="App">
      <Routes>
        <Route path="/" element={<Navigate to="/login" />} />
        <Route path="/dashboard" element={requireAuth(<Dashboard />)} />
        <Route path="/login" element={<Login />} />
        <Route path="/magic-link" element={<MagicLink />} />
        <Route path="*" element={<NotFound />} />
      </Routes>
      <ToastContainer />
    </div>
  );
}

export default App;
