import React from "react";
import { useSelector } from "react-redux";
import CompanyDashboard from "../CompanyDashboard/CompanyDashboard";
import DocumentViewer from "../DocumentViewer/DocumentViewer";
import DocumentManager from "../DocumentManager/DocumentManager";
import "./MainContent.css";

const MainContent = ({
  deals,
  selectedCompany,
  handleCompanyChange,
  clearSearch,
  searchTerm,
  setSearchTerm,
  showDropdown,
  setShowDropdown,
}) => {
  const { activeComponent, documentName } = useSelector(
    (state) => state.activeComponent
  );

  let content;
  switch (activeComponent) {
    case "companyDashboard":
      content = (
        <CompanyDashboard
          deals={deals}
          selectedCompany={selectedCompany}
          handleCompanyChange={handleCompanyChange} // Ensure consistent naming
          clearSearch={clearSearch}
          searchTerm={searchTerm}
          setSearchTerm={setSearchTerm}
          showDropdown={showDropdown}
          setShowDropdown={setShowDropdown}
        />
      );
      break;
    case "documentViewer":
      content = <DocumentViewer documentName={documentName} />;
      break;
    case "documentManager": // This case will handle the DocumentManager
      content = <DocumentManager />;
      break;
    default:
      content = <CompanyDashboard deals={deals} />;
  }

  return <div className="main-content">{content}</div>;
};

export default MainContent;
