import { createSlice } from '@reduxjs/toolkit'; // Removed jwtDecode import

export const authSlice = createSlice({
  name: 'auth',
  initialState: {
    isAuthenticated: false,
    user: null,
    error: null,
  },
  reducers: {
    loginSuccess: (state, action) => {
      state.isAuthenticated = true;
      state.user = action.payload;
      state.error = null;
    },
    loginFailure: (state, action) => {
      state.isAuthenticated = false;
      state.user = null;
      state.error = action.payload;
    },
    logout: (state) => {
      state.isAuthenticated = false;
      state.user = null;
      state.error = null;
    },
    authenticateViaMagicLinkSuccess: (state, action) => {
      state.isAuthenticated = true;
      state.user = action.payload;
      state.error = null;
    },
    authenticateViaMagicLinkFailure: (state, action) => {
      state.isAuthenticated = false;
      state.user = null;
      state.error = action.payload;
    },
  },
});

// Export the actions
export const {
  loginSuccess,
  loginFailure,
  logout,
  authenticateViaMagicLinkSuccess,
  authenticateViaMagicLinkFailure,
} = authSlice.actions;

// Thunk action for magic link authentication
export const authenticateViaMagicLink = (data) => (dispatch) => {
  try {
    // Handle response from the backend, no need for jwtDecode here
    const { email } = data;
    
    // Simulate authentication success
    const userPayload = { email }; // Extract and create user data from the backend response
    dispatch(authenticateViaMagicLinkSuccess(userPayload));
  } catch (error) {
    console.error("Error processing authentication: ", error);
    dispatch(authenticateViaMagicLinkFailure('Token validation failed'));
  }
};

export default authSlice.reducer;
