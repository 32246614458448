import React, { useEffect, useState } from "react";
import Navbar from "../../components/Navbar/Navbar";
import Sidebar from "../../components/Sidebar/Sidebar";
import MainContent from "../../components/MainContent/MainContent";
import { useSelector } from "react-redux";
import { fetchDealsByEmail } from "../../services/fetchDealsService";
import fetchStateId from "../../services/fetchStateId";
import "./Dashboard.css";

const Dashboard = () => {
  const [deals, setDeals] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [stateId, setStateId] = useState(null);
  const [selectedCompany, setSelectedCompany] = useState(null);
  const [searchTerm, setSearchTerm] = useState("");
  const [showDropdown, setShowDropdown] = useState(false);

  const { user } = useSelector((state) => state.auth);

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (user && user.email) {
          const data = await fetchDealsByEmail(user.email);

          const dealDetails = data.map((deal) => ({
            id: deal.new_dealid,
            name: deal.new_name || "No Name",
            total_employees: deal.hc_employeecount,
            state: deal.hc_addressstate,
            franchiseId: deal._hc_franchise_team_value,
          }));

          setDeals(dealDetails);

          // Ensure stateId is fetched for the first company if it exists
          if (dealDetails[0]?.state) {
            const fetchedStateId = await fetchStateId(dealDetails[0].state);
            setStateId(fetchedStateId);
          }
        }
      } catch (error) {
        setError("Failed to fetch deals.");
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [user]);

  // Handle company selection change
  const handleCompanyChange = (company) => {
    setSelectedCompany(company);

    // If the company is null, skip fetching stateId
    if (!company) {
      setStateId(null); // Clear the stateId if no company is selected
      return;
    }

    fetchStateId(company.state).then((fetchedStateId) =>
      setStateId(fetchedStateId)
    );
  };

  const clearSearch = () => {
    setSearchTerm("");
    setShowDropdown(false);
    handleCompanyChange(null); // This will reset the selected company
  };

  if (loading) return <div>Loading...</div>;
  if (error) return <div>Error: {error}</div>;

  return (
    <div className="dashboard">
      <Navbar />
      <div className="dashboard-container">
        <Sidebar
          stateId={stateId}
          franchiseId={selectedCompany?.franchiseId ?? null}
          companyId={selectedCompany?.id ?? null}
        />
        <MainContent
          deals={deals}
          selectedCompany={selectedCompany}
          handleCompanyChange={handleCompanyChange} // Ensure consistent naming
          clearSearch={clearSearch}
          searchTerm={searchTerm}
          setSearchTerm={setSearchTerm}
          showDropdown={showDropdown}
          setShowDropdown={setShowDropdown}
        />
      </div>
    </div>
  );
};

export default Dashboard;
