import fetchStateDocuments from './fetchStateDocuments';
import fetchFranchiseDocuments from './fetchFranchiseDocuments';
import fetchGeneralDocuments from './fetchGeneralDocuments';
import fetchAdditionalDocuments from './fetchAdditionalDocuments';

const fetchSidebarData = async (franchiseId, stateId, companyId) => {
  try {
    // Fetch documents from different sources
    const stateDocuments = await fetchStateDocuments(stateId);
    console.log('stateDocuments ',stateDocuments);
    const franchiseDocuments = await fetchFranchiseDocuments(franchiseId);
    console.log('franchiseDocuments', franchiseDocuments);
    const generalDocuments = await fetchGeneralDocuments();
    console.log('generalDocuments', generalDocuments);
    const additionalDocuments = await fetchAdditionalDocuments(companyId);
    console.log('additionalDocuments', additionalDocuments);

    // Combine documents into the sidebar structure, grouped by sections
    const combinedDocuments = [
      ...stateDocuments,
      ...franchiseDocuments,
      ...generalDocuments,
      ...additionalDocuments
    ];

    // Group documents by their 'section'
    const sections = combinedDocuments.reduce((acc, doc) => {
      const section = doc.section || "General"; // Default to 'General' if no section
      if (!acc[section]) {
        acc[section] = { items: [] };
      }
      acc[section].items.push({ name: doc.document_name, documentId: doc.document_id });
      return acc;
    }, {});

    return sections; // Return the grouped data
  } catch (error) {
    console.error("Error fetching sidebar data:", error);
    return {};
  }
};

export default fetchSidebarData;
