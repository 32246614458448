import React, { useState } from 'react';
import { toast } from 'react-toastify';
import axios from 'axios';
import 'react-toastify/dist/ReactToastify.css';
import './MagicLink.css';

const MagicLink = () => {
  const [email, setEmail] = useState('');

  const handleSendMagicLink = async () => {
    if (email) {
      try {
        const response = await axios.post(`${process.env.REACT_APP_API_BASE_URL}/auth/magic-link`, { email });
        if (response.status === 200) {
          toast.success('Magic link sent to your email', {
            position: "top-center",
            autoClose: 3000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: false,
            draggable: true,
            progress: undefined,
          });
        }
      } catch (error) {
        toast.error('Error sending magic link. Email not found.', {
          position: "top-center",
          autoClose: 3000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
          progress: undefined,
        });
      }
    } else {
      toast.error('Please enter a valid email address', {
        position: "top-center",
        autoClose: 3000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        progress: undefined,
      });
    }
  };

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      handleSendMagicLink();
    }
  };

  return (
    <div className="magic-link-page-container">
      <div className="magic-link-page-form">
        <h2 className="magic-link-page-title">Send Magic Link</h2>
        <input 
          className="magic-link-page-input"
          type="email" 
          placeholder="Email Address" 
          value={email} 
          onChange={(e) => setEmail(e.target.value)}
          onKeyDown={handleKeyDown} 
        />
        <button className="magic-link-page-button" onClick={handleSendMagicLink}>
          Send Magic Link
        </button>
      </div>
    </div>
  );
};

export default MagicLink;
