import React from 'react';
import { useSelector } from 'react-redux';
import DocumentActions from './DocumentActions';
import UploadedDocumentsTable from './UploadedDocumentsTable';
import dummyData from '../../data/dummyData.json';
import './DocumentViewer.css';

const DocumentViewer = () => {
  // Get the selected organization and document name from Redux
  const selectedOrganization = useSelector((state) => state.organization.selectedOrganization);
  const documentName = useSelector((state) => state.activeComponent.documentName);

  console.log("DocumentViewer selected organization:", selectedOrganization); // Log selected organization
  console.log("DocumentViewer selected document name:", documentName); // Log selected document name

  // Fetch relevant documents based on the selected organization and sidebar item
  const relevantDocuments = dummyData[selectedOrganization]?.[documentName] || [];

  console.log("Fetched relevant documents:", relevantDocuments); // Log the relevant documents

  return (
    <div className="document-viewer">
      <DocumentActions documentName={documentName} />
      <UploadedDocumentsTable documents={relevantDocuments} />
    </div>
  );
};

export default DocumentViewer;
