// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.add-document {
    margin-bottom: 30px;
  }
  
  .add-document h3 {
    font-size: 20px;
    margin-bottom: 15px;
  }
  
  .add-document-form {
    display: flex;
    flex-direction: column;
  }
  
  .add-document-form label {
    margin-bottom: 5px;
    font-weight: bold;
  }
  
  .add-document-form input,
  .add-document-form select {
    margin-bottom: 10px;
    padding: 10px;
    font-size: 16px;
    border: 1px solid #ccc;
    border-radius: 4px;
  }
  
  .add-document-button {
    padding: 10px;
    font-size: 16px;
    color: white;
    background-color: #193768;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .add-document-button:hover {
    background-color: #263f68;
  }
  `, "",{"version":3,"sources":["webpack://./src/components/AddDocument/AddDocument.css"],"names":[],"mappings":"AAAA;IACI,mBAAmB;EACrB;;EAEA;IACE,eAAe;IACf,mBAAmB;EACrB;;EAEA;IACE,aAAa;IACb,sBAAsB;EACxB;;EAEA;IACE,kBAAkB;IAClB,iBAAiB;EACnB;;EAEA;;IAEE,mBAAmB;IACnB,aAAa;IACb,eAAe;IACf,sBAAsB;IACtB,kBAAkB;EACpB;;EAEA;IACE,aAAa;IACb,eAAe;IACf,YAAY;IACZ,yBAAyB;IACzB,YAAY;IACZ,kBAAkB;IAClB,eAAe;IACf,sCAAsC;EACxC;;EAEA;IACE,yBAAyB;EAC3B","sourcesContent":[".add-document {\r\n    margin-bottom: 30px;\r\n  }\r\n  \r\n  .add-document h3 {\r\n    font-size: 20px;\r\n    margin-bottom: 15px;\r\n  }\r\n  \r\n  .add-document-form {\r\n    display: flex;\r\n    flex-direction: column;\r\n  }\r\n  \r\n  .add-document-form label {\r\n    margin-bottom: 5px;\r\n    font-weight: bold;\r\n  }\r\n  \r\n  .add-document-form input,\r\n  .add-document-form select {\r\n    margin-bottom: 10px;\r\n    padding: 10px;\r\n    font-size: 16px;\r\n    border: 1px solid #ccc;\r\n    border-radius: 4px;\r\n  }\r\n  \r\n  .add-document-button {\r\n    padding: 10px;\r\n    font-size: 16px;\r\n    color: white;\r\n    background-color: #193768;\r\n    border: none;\r\n    border-radius: 4px;\r\n    cursor: pointer;\r\n    transition: background-color 0.3s ease;\r\n  }\r\n  \r\n  .add-document-button:hover {\r\n    background-color: #263f68;\r\n  }\r\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
