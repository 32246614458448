const sidebarData = [
  {
    section: "Dashboard", 
    icon: "dashboard", 
    roles: ["user", "admin", "manager"],  // Role-based access for the entire section
    items: [
      { name: "Company Dashboard", component: "companyDashboard" }
    ]
  },
  {
    section: "Basic Information",
    icon: "info", 
    roles: ["user", "admin", "manager"],  // Role-based access for the entire section
    items: [
      { name: "Business", component: "documentViewer", documentName: "Business" },
      { name: "Payroll Process", component: "documentViewer", documentName: "Payroll Process" },
      { name: "Services", component: "documentViewer", documentName: "Services" },
    ]
  },
  {
    section: "Required Documents",
    icon: "description", 
    roles: ["user", "admin", "manager"],  // Role-based access for the entire section
    items: [
      { name: "W4", component: "documentViewer", documentName: "W4" },
      { name: "W9", component: "documentViewer", documentName: "W9" },
      { name: "Priors", component: "documentViewer", documentName: "Priors" },
      { name: "Accural Docs", component: "documentViewer", documentName: "Accural Docs" },
    ]
  },
  {
    section: "Agreements",
    icon: "gavel", 
    roles: ["user", "admin", "manager"],  // Role-based access for the entire section
    items: [
      { name: "POA", component: "documentViewer", documentName: "POA" },
      { name: "ACH", component: "documentViewer", documentName: "ACH" },
      { name: "Agreement", component: "documentViewer", documentName: "Agreement" }
    ]
  },
  {
    section: "Doc Management",  // New section only for admin and manager
    icon: "folder",
    roles: ["admin", "manager"],  // Role-based access for the entire section
    items: [
      { name: "Manage Documents", component: "documentManager" }
    ]
  },
];

export default sidebarData;
