import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { setActiveComponent } from '../../redux/slices/activeComponentSlice';
import fetchSidebarData from '../../services/fetchSidebarData'; // Fetch the combined sidebar data
import './Sidebar.css';

const Sidebar = ({ franchiseId, stateId, companyId }) => {  // Accept franchiseId, stateId, and companyId as props
  const dispatch = useDispatch();
  const [openSections, setOpenSections] = useState({});
  const [sidebarData, setSidebarData] = useState([]); // State for dynamically fetched sidebar data
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchDocuments = async () => {
      try {
        // Pass the dynamically received franchiseId, stateId, and companyId to fetchSidebarData
        const fetchedData = await fetchSidebarData(franchiseId, stateId, companyId);
        console.log('Franchise ID: ', franchiseId);
        console.log('State ID: ', stateId);
        console.log('Company ID: ', companyId);
        setSidebarData(fetchedData); // Set the fetched sidebar data
      } catch (error) {
        console.error("Error fetching sidebar data:", error);
      } finally {
        setLoading(false); // Stop loading once data is fetched
      }
    };

    // Fetch the sidebar data only if all required IDs are present
    if (franchiseId && stateId && companyId) {
      setLoading(true); // Start loading before fetching
      fetchDocuments();
    } else {
      setSidebarData([]);  // Clear the sidebarData when no company is selected
    }
  }, [franchiseId, stateId, companyId]);  // Re-fetch when the IDs change

  const toggleSection = (section) => {
    setOpenSections(prevOpenSections => ({
      ...prevOpenSections,
      [section]: !prevOpenSections[section]
    }));
  };

  const handleItemClick = (componentName, documentName = '') => {
    dispatch(setActiveComponent({ component: componentName, documentName }));
  };

  return (
    <aside className="sidebar">
      {/* Company Dashboard item (always visible) */}
      <div className="sidebar-section">
        <div className="sidebar-section-title" onClick={() => handleItemClick('companyDashboard')}>
          <div className="section-title-content">
            <span className="material-symbols-outlined">dashboard</span>
            <span className="sidebar-section-name">Company Dashboard</span>
          </div>
        </div>
      </div>

      {/* Document Manager link (always visible) */}
      <div className="sidebar-section">
        <div className="sidebar-section-title" onClick={() => handleItemClick('documentManager')}>
          <div className="section-title-content">
            <span className="material-symbols-outlined">folder_shared</span>
            <span className="sidebar-section-name">Document Manager</span>
          </div>
        </div>
      </div>

      {loading ? (
        <div>Loading Sidebar...</div>
      ) : !sidebarData || sidebarData.length === 0 ? (
        <div className='no-company-selected'>Please select a company to display the documents</div>
      ) : (
        Object.entries(sidebarData).map(([sectionName, sectionData], index) => (
          <div key={index} className="sidebar-section">
            <div className="sidebar-section-title" onClick={() => toggleSection(sectionName)}>
              <div className="section-title-content">
                <span className="material-symbols-outlined">{sectionData.icon || "folder"}</span>
                <span className="sidebar-section-name">{sectionName}</span>
              </div>
              <span className="material-symbols-outlined arrow-icon">
                {openSections[sectionName] ? 'arrow_drop_up' : 'arrow_drop_down'}
              </span>
            </div>
            {openSections[sectionName] && (
              <ul className="sidebar-items">
                {sectionData.items.map((item, idx) => (
                  <li
                    key={idx}
                    className="sidebar-item"
                    onClick={() => handleItemClick("documentViewer", item.name)}
                  >
                    {item.name}
                  </li>
                ))}
              </ul>
            )}
          </div>
        ))
      )}
    </aside>
  );
};

export default Sidebar;
