import React, { useState, useEffect, useRef } from "react";
import { useDispatch } from "react-redux";
import { setSelectedOrganization } from "../../redux/slices/organizationSlice";
import fetchFranchiseName from "../../services/fetchFranchiseName";
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import DocumentModal from "../DocumentModal/DocumentModal";
import "./CompanyDashboard.css";

const CompanyDashboard = ({
  deals = [],
  selectedCompany,
  handleCompanyChange,
  clearSearch,
  searchTerm,
  setSearchTerm,
  showDropdown,
  setShowDropdown,
}) => {
  const dispatch = useDispatch();
  const dropdownRef = useRef(null);

  const [documentStatus, setDocumentStatus] = useState([]);
  const [checklist, setChecklist] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [franchiseName, setFranchiseName] = useState("");
  const employeeStatus = [];

  useEffect(() => {
    if (deals.length === 1) {
      handleCompanyChange(deals[0]);
      setSearchTerm(deals[0].name);
    }
  }, [deals, handleCompanyChange]);

  useEffect(() => {
    if (selectedCompany) {
      dispatch(setSelectedOrganization(selectedCompany.name));
      console.log("Dispatched organization:", selectedCompany.name);

      // Fetch franchise name if franchiseId is available
      if (selectedCompany.franchiseId) {
        fetchFranchiseName(selectedCompany.franchiseId)
          .then((name) => setFranchiseName(name)) // Store the fetched franchise name
          .catch((error) =>
            console.error("Error fetching franchise name:", error)
          );
      } else {
        setFranchiseName("N/A");
      }

      // Mock employee status and checklist data based on selectedCompany ID
      let employeeStatusData;
      let checklistData;

      if (selectedCompany.id === "9cfce7be-7e75-ef11-a670-0022482dfc34") {
        employeeStatusData = [
          {
            documentType: "W-4 Form",
            uploadedDocuments: 15,
            totalDocuments: 30,
            status: "Incomplete",
          },
          {
            documentType: "I-9 Form",
            uploadedDocuments: 30,
            totalDocuments: 30,
            status: "Complete",
          },
        ];
        checklistData = [
          {
            documentName: "W-4 Form",
            uploadDate: "2024-09-01",
            completionStatus: "incomplete",
          },
          {
            documentName: "I-9 Form",
            uploadDate: "2024-09-05",
            completionStatus: "complete",
          },
        ];
      } else if (
        selectedCompany.id === "6912d817-cdd5-4ddd-8c92-7770233272f5"
      ) {
        employeeStatusData = [
          {
            documentType: "W-4 Form",
            uploadedDocuments: 10,
            totalDocuments: 40,
            status: "Incomplete",
          },
          {
            documentType: "I-9 Form",
            uploadedDocuments: 40,
            totalDocuments: 40,
            status: "Complete",
          },
        ];
        checklistData = [
          {
            documentName: "W-4 Form",
            uploadDate: "2024-09-10",
            completionStatus: "incomplete",
          },
          {
            documentName: "I-9 Form",
            uploadDate: "2024-09-12",
            completionStatus: "complete",
          },
        ];
      } else {
        employeeStatusData = [
          {
            documentType: "W-4 Form",
            uploadedDocuments: 25,
            totalDocuments: 30,
            status: "Incomplete",
          },
          {
            documentType: "I-9 Form",
            uploadedDocuments: 30,
            totalDocuments: 30,
            status: "Complete",
          },
        ];
        checklistData = [
          {
            documentName: "W-4 Form",
            uploadDate: "2024-09-15",
            completionStatus: "complete",
          },
          {
            documentName: "I-9 Form",
            uploadDate: "2024-09-10",
            completionStatus: "incomplete",
          },
        ];
      }

      // Set data based on the selected company
      setDocumentStatus(employeeStatusData);
      setChecklist(checklistData);
    } else {
      // Clear the state when no company is selected
      setDocumentStatus([]);
      setChecklist([]);
      setFranchiseName(""); // Clear franchise name if no company is selected
    }
  }, [selectedCompany, dispatch]);

  // Handle outside click to hide dropdown
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setShowDropdown(false);
      }
    };

    if (showDropdown) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [showDropdown]);

  const handleDealChange = (deal) => {
    handleCompanyChange(deal); // Update the parent component
    setSearchTerm(deal.name);
    setShowDropdown(false);
    console.log("Selected deal changed:", deal);
  };

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
    setShowDropdown(true); // Show dropdown on typing
  };

  const handleInputClick = () => {
    if (deals.length > 0) {
      setShowDropdown(true);
    }
  };

  const calculateProgress = () => {
    const totalDocuments = documentStatus.reduce(
      (acc, doc) => acc + doc.totalDocuments,
      0
    );
    const uploadedDocuments = documentStatus.reduce(
      (acc, doc) => acc + doc.uploadedDocuments,
      0
    );
    return totalDocuments > 0 ? (uploadedDocuments / totalDocuments) * 100 : 0;
  };

  const openModal = () => setIsModalOpen(true);
  const closeModal = () => setIsModalOpen(false);

  const lastUploadedDocumentUrl = "/UploadedDocuments/W4-Employee.pdf";

  return (
    <div className="company-dashboard">
      <div className="top-container">
        <div className="company-selector">
          <label htmlFor="deal-select">Search By Name:</label>

          <div className="search-container">
            <input
              type="text"
              id="company-search"
              placeholder={
                deals.length === 1 ? deals[0].name : "Search company..."
              }
              value={searchTerm}
              onChange={handleSearchChange}
              onClick={handleInputClick}
              disabled={deals.length === 1}
              autoComplete="off"
            />

            {/* Render clear (X) icon when search term exists */}
            {searchTerm ? (
              <span
                className="material-symbols-outlined clear-icon"
                onClick={clearSearch}
              >
                close
              </span>
            ) : (
              <span className="material-symbols-outlined search-icon">
                search
              </span>
            )}
          </div>

          {/* Custom dropdown for selecting a deal */}
          {showDropdown && deals.length > 1 && (
            <ul className="dropdown" ref={dropdownRef}>
              {deals
                .filter((deal) =>
                  deal.name.toLowerCase().includes(searchTerm.toLowerCase())
                )
                .map((deal) => (
                  <li
                    key={deal.id}
                    className="dropdown-item"
                    onClick={() => handleDealChange(deal)}
                  >
                    {deal.name}
                  </li>
                ))}
              {/* Show no matches message */}
              {deals.filter((deal) =>
                deal.name.toLowerCase().includes(searchTerm.toLowerCase())
              ).length === 0 && (
                <li className="dropdown-item no-match">No deals found</li>
              )}
            </ul>
          )}

          <div className="company-info">
            <h3>{selectedCompany?.name || "No Company Selected"}</h3>
            <p>
              <strong>State:</strong> {selectedCompany?.state || "N/A"}
            </p>
            <p>
              <strong>Franchise:</strong> {franchiseName || "N/A"}
            </p>
            <p>
              <strong>Total Employees:</strong>{" "}
              {selectedCompany?.total_employees || "N/A"}
            </p>
          </div>
        </div>

        <div className="progress-card">
          <h3 className="progress-card-heading">Document Upload Progress</h3>
          <CircularProgressbar
            value={calculateProgress()}
            text={`${Math.round(calculateProgress())}%`}
            styles={buildStyles({
              textColor: "#333",
              pathColor: calculateProgress() === 100 ? "#4c8c40" : "#4c8c40",
              trailColor: "#d6d6d6",
            })}
            style={{ width: "200px" }}
          />
        </div>
      </div>

      <div className="middle-container">
        {selectedCompany ? (
          <div className="right-section">
            <h4>Documents Checklist</h4>
            <table className="recent-uploads-table">
              <thead>
                <tr>
                  <th>Document Name</th>
                  <th>Date Uploaded</th>
                  <th>Uploaded Documents</th>{" "}
                  {/* Column for Uploaded Documents */}
                  <th>Total Documents</th> {/* Column for Total Documents */}
                  <th>Completion Status</th> {/* Status */}
                </tr>
              </thead>
              <tbody>
                {documentStatus.map((document, idx) => (
                  <tr key={idx}>
                    <td>{document.documentType}</td>
                    <td>{checklist[idx]?.uploadDate || "N/A"}</td>
                    <td>{document.uploadedDocuments}</td>{" "}
                    {/* Uploaded Documents */}
                    <td>{document.totalDocuments}</td> {/* Total Documents */}
                    <td>
                      <button className="view-button" onClick={openModal}>
                        <span
                          className={`material-symbols-outlined ${
                            document.status === "Complete"
                              ? "status-complete"
                              : "status-incomplete"
                          }`}
                        >
                          {document.status === "Complete"
                            ? "check_circle"
                            : "cancel"}
                        </span>
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        ) : (
          <div className="no-company-selected">
            <p>Please select a company to view its dashboard.</p>
          </div>
        )}
      </div>

      {selectedCompany && (
        <DocumentModal
          isOpen={isModalOpen}
          onClose={closeModal}
          documentUrl={lastUploadedDocumentUrl}
        />
      )}
    </div>
  );
};

export default CompanyDashboard;
