const fetchStateId = async (stateAbbreviation) => {
    try {
        console.log('State abbr: ', stateAbbreviation)
      const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/states/by-abbreviation?abbreviation=${stateAbbreviation}`);
      console.log('Response:', response);
      if (!response.ok) {
        throw new Error('Failed to fetch state ID');
      }
      const data = await response.json();
      console.log('State ID: ',data.state_id)
      return data.state_id; // Return state_id from response
    } catch (error) {
      console.error('Error fetching state ID:', error);
      return null; // Return null in case of error
    }
  };
  
  export default fetchStateId;
  