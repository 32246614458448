import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { loginSuccess, loginFailure } from '../../redux/slices/authSlice';
import { setActiveComponent } from '../../redux/slices/activeComponentSlice';
import { setSelectedOrganization } from '../../redux/slices/organizationSlice';
import './Login.css';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const Login = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');

  const handleLogin = () => {

    const dummyUsers = [
      { username: 'admin', password: 'admin', role: 'admin' },
      { username: 'manager', password: 'manager', role: 'manager', owning_team: 'Tucson' },
      { username: 'user1', password: 'user1', role: 'user', organization: 'Organization 4', owning_team: 'Tucson' },
      { username: 'user2', password: 'user2', role: 'user', organization: 'Organization 2', owning_team: 'New York' }
    ];
    
    
    const user = dummyUsers.find(u => u.username === username && u.password === password);
  
    if (user) {
      dispatch(loginSuccess(user));
      dispatch(setSelectedOrganization(user.organization));
  
      toast.success('Login successful', {
        position: "top-center",
        autoClose: 3000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        progress: undefined,
      });
  
      // Set the default active component based on role
      if (user.role === 'admin' || user.role === 'manager') {
        dispatch(setActiveComponent({ component: 'companyDashboard' }));
      } else {
        dispatch(setActiveComponent({ component: 'companyDashboard' }));
      }
  
      // Redirect to dashboard
      navigate('/dashboard');
    } else {
      dispatch(loginFailure('Invalid username or password'));
      toast.error('Invalid username or password', {
        position: "top-center",
        autoClose: 3000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        progress: undefined,
      });
    }
  };
  
  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      handleLogin();
    }
  };

  return (
    <div className="login-page-container">
      <div className="login-page-form">
        <h2 className="login-page-title">Login</h2>
        <input 
          className="login-page-input"
          type="text" 
          placeholder="Username" 
          value={username} 
          onChange={(e) => setUsername(e.target.value)}
          onKeyDown={handleKeyDown} 
        />
        <input 
          className="login-page-input"
          type="password" 
          placeholder="Password" 
          value={password} 
          onChange={(e) => setPassword(e.target.value)}
          onKeyDown={handleKeyDown} 
        />
        <button className="login-page-button" onClick={handleLogin}>Login</button>
      </div>
    </div>
  );
};

export default Login;
