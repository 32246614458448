import axios from 'axios';

// Fetch deals by email from the backend
export const fetchDealsByEmail = async (email) => {
  try {
    console.log(`Fetching deals for email: ${email}`); // Debugging: Log email
    const response = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/deals?email=${email}`);
    console.log('Response from fetching deal: ',response);
    return response.data;
  } catch (error) {
    console.error('Error fetching deals:', error); // Log error details
    throw error; // Handle this in the component
  }
};
