// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.login-page-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  background: linear-gradient(135deg, #ece9e6, #ffffff);
  font-family: 'Arial', sans-serif;
}

.login-page-title {
  font-size: 28px;
  margin-bottom: 20px;
  color: #333;
}

.login-page-form {
  display: flex;
  flex-direction: column;
  width: 320px;
  background-color: #fff;
  padding: 30px;
  border-radius: 10px;
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.1);
}

.login-page-input {
  margin: 10px 0;
  padding: 12px;
  font-size: 16px;
  border: 1px solid #ccc;
  border-radius: 5px;
  transition: border-color 0.3s;
}

.login-page-input:focus {
  border-color: #263f68;
  outline: none;
}

.login-page-button {
  padding: 12px;
  font-size: 16px;
  font-weight: bold;
  background-color: #193768;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s, transform 0.2s;
}

.login-page-button:hover {
  background-color: #263f68;
  transform: translateY(-2px);
}

.login-page-button:active {
  background-color: #263f68;
  transform: translateY(0);
}
`, "",{"version":3,"sources":["webpack://./src/pages/Login/Login.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,sBAAsB;EACtB,mBAAmB;EACnB,uBAAuB;EACvB,aAAa;EACb,qDAAqD;EACrD,gCAAgC;AAClC;;AAEA;EACE,eAAe;EACf,mBAAmB;EACnB,WAAW;AACb;;AAEA;EACE,aAAa;EACb,sBAAsB;EACtB,YAAY;EACZ,sBAAsB;EACtB,aAAa;EACb,mBAAmB;EACnB,2CAA2C;AAC7C;;AAEA;EACE,cAAc;EACd,aAAa;EACb,eAAe;EACf,sBAAsB;EACtB,kBAAkB;EAClB,6BAA6B;AAC/B;;AAEA;EACE,qBAAqB;EACrB,aAAa;AACf;;AAEA;EACE,aAAa;EACb,eAAe;EACf,iBAAiB;EACjB,yBAAyB;EACzB,YAAY;EACZ,YAAY;EACZ,kBAAkB;EAClB,eAAe;EACf,iDAAiD;AACnD;;AAEA;EACE,yBAAyB;EACzB,2BAA2B;AAC7B;;AAEA;EACE,yBAAyB;EACzB,wBAAwB;AAC1B","sourcesContent":[".login-page-container {\r\n  display: flex;\r\n  flex-direction: column;\r\n  align-items: center;\r\n  justify-content: center;\r\n  height: 100vh;\r\n  background: linear-gradient(135deg, #ece9e6, #ffffff);\r\n  font-family: 'Arial', sans-serif;\r\n}\r\n\r\n.login-page-title {\r\n  font-size: 28px;\r\n  margin-bottom: 20px;\r\n  color: #333;\r\n}\r\n\r\n.login-page-form {\r\n  display: flex;\r\n  flex-direction: column;\r\n  width: 320px;\r\n  background-color: #fff;\r\n  padding: 30px;\r\n  border-radius: 10px;\r\n  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.1);\r\n}\r\n\r\n.login-page-input {\r\n  margin: 10px 0;\r\n  padding: 12px;\r\n  font-size: 16px;\r\n  border: 1px solid #ccc;\r\n  border-radius: 5px;\r\n  transition: border-color 0.3s;\r\n}\r\n\r\n.login-page-input:focus {\r\n  border-color: #263f68;\r\n  outline: none;\r\n}\r\n\r\n.login-page-button {\r\n  padding: 12px;\r\n  font-size: 16px;\r\n  font-weight: bold;\r\n  background-color: #193768;\r\n  color: white;\r\n  border: none;\r\n  border-radius: 5px;\r\n  cursor: pointer;\r\n  transition: background-color 0.3s, transform 0.2s;\r\n}\r\n\r\n.login-page-button:hover {\r\n  background-color: #263f68;\r\n  transform: translateY(-2px);\r\n}\r\n\r\n.login-page-button:active {\r\n  background-color: #263f68;\r\n  transform: translateY(0);\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
