import React, { useState, useEffect } from "react";
import DocumentModal from "../DocumentModal/DocumentModal";
import ESignModal from "../TempModal/TempModal";
import UploadDocumentModal from "../UploadDocumentModal/UploadDocumentModal";
import * as pdfjsLib from "pdfjs-dist/webpack";
import "./DocumentActions.css";

const DocumentActions = ({ documentName }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isESignModalOpen, setIsESignModalOpen] = useState(false);
  const [isUploadModalOpen, setIsUploadModalOpen] = useState(false);
  const [isBulkUpload, setIsBulkUpload] = useState(false);
  const [thumbnail, setThumbnail] = useState(null);

  useEffect(() => {
    const generateThumbnail = async () => {
      const loadingTask = pdfjsLib.getDocument("/UploadedDocuments/W4-Employee.pdf");
      const pdf = await loadingTask.promise;
      const page = await pdf.getPage(1);
      const scale = 0.5;
      const viewport = page.getViewport({ scale });
      const canvas = document.createElement("canvas");
      const context = canvas.getContext("2d");
      canvas.height = viewport.height;
      canvas.width = viewport.width;

      const renderContext = {
        canvasContext: context,
        viewport: viewport,
      };
      await page.render(renderContext).promise;
      setThumbnail(canvas.toDataURL());
    };

    generateThumbnail();
  }, []);

  const handleViewDocument = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const handleESignDocument = () => {
    setIsESignModalOpen(true);
  };

  const closeESignModal = () => {
    setIsESignModalOpen(false);
  };

  const handleConfirmESign = () => {
    alert("Document signed successfully!");
    setIsESignModalOpen(false);
  };

  const handleUploadSingle = () => {
    setIsBulkUpload(false);
    setIsUploadModalOpen(true);
  };

  const handleUploadBulk = () => {
    setIsBulkUpload(true);
    setIsUploadModalOpen(true);
  };

  const closeUploadModal = () => {
    setIsUploadModalOpen(false);
  };

  const handleConfirmUpload = (files, tags) => {
    if (isBulkUpload) {
      files.forEach((file, index) => {
        alert(`Uploaded ${file.name} with tag ${tags[index]}`);
      });
    } else {
      alert(`Uploaded ${files[0].name} with tag ${tags[0]}`);
    }
    setIsUploadModalOpen(false);
  };

  return (
    <div className="document-actions">
      <div className="action-buttons">
        <h3>{documentName}</h3>
        <button onClick={handleViewDocument}>View Document</button>
        <button onClick={handleUploadSingle}>Upload Single</button>
        <button onClick={handleUploadBulk}>Upload Bulk</button>
        <button onClick={handleESignDocument}>E-sign Document</button>
      </div>
      <div className="document-thumbnail-container">
        <h3>Document Thumbnail</h3>
        <div className="document-thumbnail">
          {thumbnail ? (
            <img src={thumbnail} alt="PDF Thumbnail" />
          ) : (
            <div className="thumbnail-placeholder">Loading...</div>
          )}
        </div>
      </div>
      <div className="document-details">
        <h3>Document Information</h3>
        <p>
          <strong>Document Description:</strong> Description of the document.
        </p>
        <p>
          <strong>Document Purpose:</strong> Purpose of the document.
        </p>
      </div>

      {/* Hidden file inputs for upload */}
      <input
        type="file"
        id="bulk-file-upload"
        style={{ display: "none" }}
        multiple
        onChange={handleUploadBulk}
      />

      {/* Document Modal */}
      <DocumentModal
        isOpen={isModalOpen}
        onClose={closeModal}
        documentUrl="/UploadedDocuments/W4-Employee.pdf"
      />

      <ESignModal
        isOpen={isESignModalOpen}
        onClose={closeESignModal}
        onConfirm={handleConfirmESign}
        documentUrl="/UploadedDocuments/W4-Employee.pdf"
      />

      {/* Upload Document Modal */}
      <UploadDocumentModal
        isOpen={isUploadModalOpen}
        onClose={closeUploadModal}
        onConfirm={handleConfirmUpload}
        isBulkUpload={isBulkUpload} // Pass the isBulkUpload flag to the modal
      />
    </div>
  );
};

export default DocumentActions;
