const fetchFranchiseDocuments = async (franchiseId) => {
  try {
    const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/documents/franchise?franchiseId=${franchiseId}`);
    console.log(response);
    if (!response.ok) {
      throw new Error("Failed to fetch franchise documents");
    }
    const data = await response.json();
    return data.map(doc => ({
      document_id: doc.document_id,
      document_name: doc.document_name,
      section: doc.section
    }));
  } catch (error) {
    console.error("Error fetching franchise documents:", error);
    return []; // Return an empty array on error
  }
};

export default fetchFranchiseDocuments;
