import React, { useState, useEffect } from "react";
import { toast } from "react-toastify";
import "./AddDocument.css";
import sidebarData from "../Sidebar/sidebarData";
import customers from "../../data/customers.json"; // Assuming customers are still static or being fetched elsewhere

const AddDocument = ({ documentType, setDocumentType }) => {
  const [documentName, setDocumentName] = useState("");
  const [section, setSection] = useState("");
  const [selectedCustomer, setSelectedCustomer] = useState("");
  const [selectedFranchise, setSelectedFranchise] = useState(""); // For Franchise Documents
  const [selectedState, setSelectedState] = useState(""); // For State Documents
  const [file, setFile] = useState(null); // File state to store the uploaded document
  const [documents, setDocuments] = useState([]); // For displaying documents
  const [editingDocument, setEditingDocument] = useState(null); // For updating documents
  const [franchises, setFranchises] = useState([]); // State for fetched franchises
  const [states, setStates] = useState([]); // State for fetched states

  const apiUrl = process.env.REACT_APP_API_URL; // Get the API URL from the .env file

  // Fetch documents from the backend
  const fetchDocuments = async () => {
    try {
      const response = await fetch(
        `${apiUrl}/documents?documentType=${documentType}`
      );
      const data = await response.json();

      // Ensure that data is an array before setting it
      if (Array.isArray(data)) {
        setDocuments(data);
      } else {
        setDocuments([]); // Set an empty array if data is not an array
      }
    } catch (error) {
      console.error("Error fetching documents:", error);
      setDocuments([]); // Set to empty array on error to prevent map issues
    }
  };

  // Fetch franchises from the backend
  const fetchFranchises = async () => {
    try {
      const response = await fetch(`${apiUrl}/franchises`); // Fetch franchises from the database
      const data = await response.json();
      if (Array.isArray(data)) {
        setFranchises(data); // Store the fetched franchises in state
      } else {
        setFranchises([]);
      }
    } catch (error) {
      console.error("Error fetching franchises:", error);
    }
  };

  // Fetch states from the backend
  const fetchStates = async () => {
    try {
      const response = await fetch(`${apiUrl}/states`); // Fetch states from the database
      const data = await response.json();
      if (Array.isArray(data)) {
        setStates(data); // Store the fetched states in state
      } else {
        setStates([]);
      }
    } catch (error) {
      console.error("Error fetching states:", error);
    }
  };

  useEffect(() => {
    if (documentType === "Franchise Document") {
      fetchFranchises(); // Fetch franchises if "Franchise Document" is selected
    } else if (documentType === "State Document") {
      fetchStates(); // Fetch states if "State Document" is selected
    }
  }, [documentType]);

  // Handle adding or updating the document
const handleAddOrUpdateDocument = async () => {
  if (
    !documentName ||
    !section ||
    (documentType === "Additional Document" && !selectedCustomer) ||
    (documentType === "Franchise Document" && !selectedFranchise) || // This should be the franchise ID
    (documentType === "State Document" && !selectedState) // This should be the state ID
  ) {
    toast.error("Please fill in all fields.", {
      position: "top-center",
      autoClose: 3000,
    });
    return;
  }

  if (!file) {
    toast.error("Please select a file to upload.", {
      position: "top-center",
      autoClose: 3000,
    });
    return;
  }

  console.log('Selected State: ', selectedState);
  console.log('Selected Franchise: ', selectedFranchise);
  console.log('Section: ', section);

  const formData = new FormData();
  formData.append("documentName", documentName);
  formData.append("documentType", documentType);
  formData.append("section", section); // Ensure section is included
  formData.append("file", file); // Attach the file

  if (documentType === "Additional Document") {
    const selectedCompany = customers.find(c => c.companyId === selectedCustomer);
    formData.append("companyId", selectedCustomer);
    formData.append("companyName", selectedCompany ? selectedCompany.companyName : ""); // Pass company name along with company ID
  }else if (documentType === "Franchise Document") {
    formData.append("franchiseId", selectedFranchise); // Attach franchise ID
  } else if (documentType === "State Document") {
    formData.append("stateId", selectedState); // Attach state ID instead of selectedState
  }

  try {
    const method = editingDocument ? "PUT" : "POST";
    const url = editingDocument
      ? `${apiUrl}/documents/${editingDocument.document_id}`
      : `${apiUrl}/documents`;

    const response = await fetch(url, {
      method,
      body: formData, // Send formData instead of JSON
    });

    if (response.ok) {
      toast.success(
        `Document ${editingDocument ? "updated" : "added"} successfully!`,
        {
          position: "top-center",
          autoClose: 3000,
        }
      );
      fetchDocuments(); // Refresh document list
      resetForm();
    } else {
      toast.error("Error adding/updating document.", {
        position: "top-center",
        autoClose: 3000,
      });
    }
  } catch (error) {
    toast.error("Server error. Please try again later.", {
      position: "top-center",
      autoClose: 3000,
    });
  }
};


  // Reset form after adding or editing
  const resetForm = () => {
    setDocumentName("");
    setSection("");
    setSelectedCustomer("");
    setSelectedFranchise(""); // Reset franchise selection
    setSelectedState(""); // Reset state selection
    setFile(null);
    setDocumentType("Select document type");
    setEditingDocument(null);
  };

  // Edit document
  const handleEditDocument = (document) => {
    setDocumentName(document.document_name);
    setSection(
      document.section ||
        document.state ||
        document.franchise ||
        document.company_name
    ); // Adjust based on the type
    setSelectedCustomer(document.company_id || "");
    setSelectedFranchise(document.franchise || ""); // Set franchise when editing franchise document
    setSelectedState(document.state || ""); // Set state when editing state document
    setEditingDocument(document);
  };

  // Delete document
  const handleDeleteDocument = async (documentId) => {
    if (!window.confirm("Are you sure you want to delete this document?"))
      return;

    try {
      const response = await fetch(`${apiUrl}/documents/${documentId}`, {
        method: "DELETE",
      });

      if (response.ok) {
        toast.success("Document deleted successfully!", {
          position: "top-center",
          autoClose: 3000,
        });
        fetchDocuments();
      } else {
        toast.error("Error deleting document.", {
          position: "top-center",
          autoClose: 3000,
        });
      }
    } catch (error) {
      toast.error("Server error. Please try again later.", {
        position: "top-center",
        autoClose: 3000,
      });
    }
  };

  // View document (for now just shows a toast, you can extend this)
  const handleViewDocument = (document) => {
    toast.info(`Viewing document: ${document.document_name}`, {
      position: "top-center",
      autoClose: 3000,
    });
  };

  return (
    <div className="add-document">
      <h3>{editingDocument ? "Edit" : "Add"} Document</h3>
      <div className="add-document-form">
        <label htmlFor="document-type">Document Type</label>
        <select
          id="document-type"
          value={documentType}
          onChange={(e) => setDocumentType(e.target.value)}
        >
          <option value="">Select a document type...</option>
          <option value="State Document">
            State Document (State specific)
          </option>
          <option value="Franchise Document">
            Franchise Document (Franchise specific)
          </option>
          <option value="General Document">
            General Document (Accessible by all franchisees)
          </option>
          <option value="Additional Document">
            Additional Document (Specific to customer)
          </option>
        </select>

        <label htmlFor="document-name">Document Name</label>
        <input
          type="text"
          id="document-name"
          placeholder="Enter document name"
          value={documentName}
          onChange={(e) => setDocumentName(e.target.value)}
        />

        <label htmlFor="section">Menu Section</label>
        <select
          id="section"
          value={section}
          onChange={(e) => setSection(e.target.value)}
        >
          <option value="">Select Section</option>
          {sidebarData
            .filter(
              (sec) =>
                sec.section !== "Document Management" &&
                sec.section !== "Dashboard"
            )
            .map((sec, index) => (
              <option key={index} value={sec.section}>
                {sec.section}
              </option>
            ))}
        </select>

        {documentType === "Additional Document" && (
          <>
            <label htmlFor="customer">Select Customer</label>
            <select
              id="customer"
              value={selectedCustomer}
              onChange={(e) => setSelectedCustomer(e.target.value)}
            >
              <option value="">Select Customer</option>
              {customers.map((customer, index) => (
                <option key={index} value={customer.companyId}>
                  {customer.companyName}
                </option>
              ))}
            </select>
          </>
        )}

        {documentType === "Franchise Document" && (
          <>
            <label htmlFor="franchise">Select Franchise</label>
            <select
              id="franchise"
              value={selectedFranchise}
              onChange={(e) => setSelectedFranchise(e.target.value)}
            >
              <option value="">Select Franchise</option>
              {franchises.map((franchise, index) => (
                <option key={index} value={franchise.franchise_id}>
                  {franchise.franchise_name}
                </option>
              ))}
            </select>
          </>
        )}

        {documentType === "State Document" && (
          <>
            <label htmlFor="state">Select State</label>
            <select
              id="state"
              value={selectedState}
              onChange={(e) => setSelectedState(e.target.value)}
            >
              <option value="">Select State</option>
              {states.map((state, index) => (
                <option key={index} value={state.state_id}>
                  {state.state_name}
                </option>
              ))}
            </select>
          </>
        )}

        <label htmlFor="file">Upload Document</label>
        <input
          type="file"
          id="file"
          onChange={(e) => setFile(e.target.files[0])}
        />

        <button
          className="add-document-button"
          onClick={handleAddOrUpdateDocument}
        >
          {editingDocument ? "Update Document" : "Add Document"}
        </button>
      </div>
    </div>
  );
};

export default AddDocument;
