import React, { useState } from "react";
import DocumentModal from "../DocumentModal/DocumentModal";
import "./UploadedDocumentsTable.css";

const UploadedDocumentsTable = ({ documents }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [documentUrl, setDocumentUrl] = useState("");

  const handleViewClick = (url) => {
    setDocumentUrl(url);
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
    setDocumentUrl("");
  };

  return (
    <div className="uploaded-documents-table">
      <table>
        <thead>
          <tr>
            <th>Document Name</th>
            <th>Upload Date</th>
            <th>View Document</th>
            <th>Remove Document</th>
          </tr>
        </thead>
      </table>
      <div className="scrollable-tbody">
        <table>
          <tbody>
            {documents.length > 0 ? (
              documents.map((doc, idx) => (
                <tr key={idx}>
                  <td>{doc.document_name}</td>{" "}
                  {/* Adjust to match the correct field */}
                  <td>{new Date(doc.created_at).toLocaleDateString()}</td>{" "}
                  {/* Adjust to match the correct field */}
                  <td>
                    <button
                      className="icon-button view-button"
                      onClick={() => handleViewClick(doc.file_path)} // Adjust to the correct field for the document URL
                    >
                      <span className="material-symbols-outlined">
                        visibility
                      </span>
                    </button>
                  </td>
                  <td>
                    <button className="icon-button remove-button">
                      <span className="material-symbols-outlined">delete</span>
                    </button>
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td
                  colSpan="4"
                  style={{ textAlign: "center", padding: "1rem" }}
                >
                  No documents available for this category.
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>

      <DocumentModal
        isOpen={isModalOpen}
        onClose={handleCloseModal}
        documentUrl={documentUrl}
      />
    </div>
  );
};

export default UploadedDocumentsTable;
